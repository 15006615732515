export function getStrapiURL(path = "") {
    return `${
        process.env.NEXT_PUBLIC_API_BASEURL || "http://localhost:5000/api"
    }${path}`;
}

export function getStrapiMediaURL(path = "") {
    return `${process.env.NEXT_PUBLIC_API_MEDIA_BASEURL || ""}${path}`;
}

// Helper to make GET requests to Strapi
export async function fetchAPI(path: any) {
    return new Promise(async (resolve, reject) => {
        try {
            const requestUrl = getStrapiURL(path);
            // console.log("fetchAPI requestUrl=" + requestUrl);
            const response = await fetch(requestUrl, { method: "GET" });
            // console.log("fetchAPI response", response);
            const data = await response.json();
            // console.log("fetchAPI data", data);
            // return data;
            resolve(data);
        } catch (err: any) {
            if (typeof alert !== "undefined")
                // alert("ERROR: " + err.message ? err.message : "N/A");
                console.warn(
                    "API Error: " + err.message ? err.message : "",
                    err
                );
            reject(err);
        }
    });
}
