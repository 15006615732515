import Link from "next/link";
import { fetchAPI } from "lib/api";
import { getEntityImage, parseArticle, trackEvent } from "lib/basic";
import { useEffect, useState, useCallback } from "react";
import { useAppContext } from "./../context/state";
import Image from "next/image";
import { useRouter } from "next/router";
import { Fragment } from "react";
import SearchBar from "@/components/searchbar";
import navigationItems from "./../lib/navigationItems";

interface Section {
    section?: any;
    id?: any;
    slug?: any;
    name?: any;
    creators?: any;
}

interface Creator {
    slug?: any;
    name?: any;
    evangelist?: any;
}

export default function Navigation(): JSX.Element {
    const [rightOfMenu, setRightOfMenu] = useState<any>(null);
    const [leftOfMenu, setLeftOfMenu] = useState<any>(null);
    const [y, setY] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [visibleSearch, setVisibleSearch] = useState<boolean>(false);

    const router = useRouter();

    const AppContext = useAppContext();

    // Navigation elements
    useEffect(() => {
        // Parsing
        // @ts-ignore
        navigationItems.rightofmenu.map((article: any) => {
            article = parseArticle(article, AppContext.variant);
        });
        // @ts-ignore
        navigationItems.leftofmenu.map((article: any) => {
            article = parseArticle(article, AppContext.variant);
        });
        // RightOfMenu
        // @ts-ignore
        setRightOfMenu(navigationItems.rightofmenu[0]);
        // LeftOfMenu
        // @ts-ignore
        setLeftOfMenu(navigationItems.leftofmenu[0]);

        // Sections
        if (AppContext.sections.length === 0) {
            // @ts-ignore
            AppContext.setSections(navigationItems.sections);
        }

        // Suggested Creators
        if (AppContext.suggestedCreators.length === 0) {
            // @ts-ignore
            const creators: Creator[] =
                // @ts-ignore
                navigationItems.suggestedcreators.filter(
                    (creator: Creator) => creator.evangelist === true
                );
            AppContext.setSuggestedCreators(creators);
        }

        if (AppContext.suggestedArticles.length === 0) {
            // Parsing
            // @ts-ignore
            navigationItems.suggestedarticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            // @ts-ignore
            AppContext.setSuggestedArticles(navigationItems.suggestedarticles);
        }
    }, [navigationItems]);

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            setY(window.scrollY);
        },
        [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    // detect change on the url
    useEffect(() => {
        // console.log("router.asPath => " + router.asPath);
        setExpanded(false);
    }, [router.asPath]);

    const handleSearchBarClick = (e: React.MouseEvent<HTMLElement>) => {
        setVisibleSearch((visible) => !visible);
        trackEvent("NAVIGATION_SHOW_SEARCH");
    };

    const navExtraClass = y > 20 || router.asPath !== "/" ? "ltx_Short" : "";
    // const navExtraClass = "ltx_Short";
    const navExpandedClass = expanded ? " ltx_Expanded" : "";
    return (
        <header
            className={"ltx-Navigation " + navExtraClass + navExpandedClass}
        >
            <div>
                {/* context variant: {AppContext.variant} */}
                {/* rightOfMenu = {JSON.stringify(rightOfMenu, null, 2)} */}
                {visibleSearch === true ? (
                    <SearchBar handleHide={() => setVisibleSearch(false)} />
                ) : null}
                <div
                    className="ltx_mobile_toggle"
                    onClick={(e) => {
                        setExpanded((expanded) => !expanded);
                        if (!expanded) {
                            trackEvent("NAVIGATION_MOBILE_EXPAND");
                        } else {
                            trackEvent("NAVIGATION_MOBILE_COLLAPSE");
                        }
                    }}
                ></div>
                {/* <div className="ltx_navigation__logo">
                    <a
                        href={AppContext.externalLinks.lightricksWebsite}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            trackEvent("NAVIGATION_LOGO");
                        }}
                    >
                        <Image
                            src="/logo.svg"
                            alt={AppContext.defaultAlt}
                            width={167}
                            height={35}
                        />
                    </a>
                </div> */}
                <div className="ltx_navitaion__container flex flex-row justify-between mt-11 mb-7">
                    <Link href={leftOfMenu?.parsed_url || "/"} passHref>
                        <a
                            onClick={() => {
                                trackEvent("NAVIGATION_LEFT_ARTICLE");
                            }}
                        >
                            <div className="ltx_navigation__leftarticle">
                                <div>{leftOfMenu?.title}</div>
                            </div>
                        </a>
                    </Link>

                    <div className="ltx_navigation__sitelogo">
                        <Link href={`/`} passHref>
                            <a
                                style={{
                                    display: "inline-block",
                                }}
                                onClick={() => {
                                    trackEvent("NAVIGATION_HOME");
                                }}
                            >
                                <Image
                                    src="/site_logo.svg"
                                    alt={AppContext.defaultAlt}
                                    width={332}
                                    height={162}
                                />
                            </a>
                        </Link>
                    </div>

                    <Link href={rightOfMenu?.parsed_url || "/"} passHref>
                        <a
                            onClick={() => {
                                trackEvent("NAVIGATION_RIGHT_ARTICLE");
                            }}
                        >
                            <div className="ltx_navigation__rightarticle">
                                {rightOfMenu ? (
                                    <Fragment>
                                        <span>{rightOfMenu?.title}</span>
                                        <img
                                            width="190"
                                            height="190"
                                            style={{
                                                height: 190,
                                                aspectRatio: "1",
                                            }}
                                            src={
                                                rightOfMenu
                                                    ? getEntityImage(
                                                          rightOfMenu,
                                                          "square_image",
                                                          "thumbnail",
                                                          true
                                                      )
                                                    : ""
                                            }
                                            // alt={AppContext.defaultAlt}
                                            alt={rightOfMenu?.title}
                                        />
                                    </Fragment>
                                ) : null}
                            </div>
                        </a>
                    </Link>
                </div>
                <div className="ltx-navigation__sectionsContainer ltx_Container flex flex-row justify-items-center px-5">
                    <div className="ltx-navigation__sectionsExtras flex flex-row"></div>
                    <div className="ltx-navigation__sections mx-auto flex flex-row justify-center">
                        {AppContext.sections &&
                            AppContext.sections
                                .filter(
                                    (section: any) => section.section == null
                                )
                                .map((section: any, sectionIndex: number) => {
                                    let activeClass = "";
                                    if (
                                        router.asPath.indexOf("/creators") ===
                                        -1
                                    ) {
                                        activeClass =
                                            router.asPath ===
                                                "/sections/" + section.slug ||
                                            (AppContext.selectedSection &&
                                                section.slug ===
                                                    AppContext.selectedSection
                                                        .slug)
                                                ? "ltx_Active"
                                                : "";
                                    }
                                    if (
                                        section.library_section &&
                                        section.library_section === true &&
                                        router.asPath === "/library"
                                    )
                                        activeClass = "ltx_Active";

                                    return (
                                        <Link
                                            key={sectionIndex}
                                            href={
                                                (section.library_section &&
                                                    section.library_section !==
                                                        true) ||
                                                !section.library_section
                                                    ? `/sections/${section.slug}`
                                                    : `/library/`
                                            }
                                        >
                                            <a
                                                className={
                                                    "txt_NavigationSection px-4 " +
                                                    activeClass
                                                }
                                                onClick={() => {
                                                    trackEvent(
                                                        "NAVIGATION_SECTION",
                                                        section.slug
                                                    );
                                                }}
                                            >
                                                {section.name}
                                            </a>
                                        </Link>
                                    );
                                })}
                        <Link href={`/creators/`}>
                            <a
                                className={
                                    "txt_NavigationSection px-4 " +
                                    (router.asPath.indexOf("/creators") !== -1
                                        ? "ltx_Active"
                                        : "")
                                }
                            >
                                CREATORS
                            </a>
                        </Link>
                        <div
                            // href={""}
                            className="ltx_search"
                            aria-label="Search"
                            onClick={(e) => handleSearchBarClick(e)}
                        ></div>
                    </div>
                    <div className="ltx-navigation__sectionsExtras flex flex-row">
                        {/* <Link href={"/aboutus"} passHref>
                            <a
                                className={
                                    router.asPath.indexOf("/aboutus") !== -1
                                        ? "ltx_aboutus ltx_selected"
                                        : "ltx_aboutus"
                                }
                            >
                                About us
                            </a>
                        </Link> */}
                        {/* <Link href={"/library"}> */}

                        {/* </Link> */}
                        {/* <a
                            href={AppContext.externalLinks.facebook}
                            target="_blank"
                            rel="noreferrer"
                            className="ltx_facebook"
                            aria-label="Facebook"
                        ></a>
                        <a
                            href={AppContext.externalLinks.twitter}
                            target="_blank"
                            rel="noreferrer"
                            className="ltx_twitter"
                            aria-label="Twitter"
                        ></a>
                        <a
                            href={AppContext.externalLinks.instagram}
                            target="_blank"
                            rel="noreferrer"
                            className="ltx_instagram"
                            aria-label="Instagram"
                        ></a>
                        <a
                            href={AppContext.externalLinks.tiktok}
                            target="_blank"
                            rel="noreferrer"
                            className="ltx_tiktok"
                            aria-label="Tiktok"
                        ></a> */}
                    </div>
                </div>
            </div>
        </header>
    );
}
