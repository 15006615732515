import Link from "next/link";
import { useAppContext } from "../context/state";
import Image from "next/image";

export default function Subscribe(): JSX.Element {
    const AppContext = useAppContext();

    return (
        <footer className="ltx-Footer  bg-black">
            <div className="ltx_Container">
                <div>
                    {/* <Link
                        href={AppContext.externalLinks.lightricksWebsite}
                        passHref
                    >
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="ltx_footer__logo"
                        >
                            <Image
                                src="/logo_white.svg"
                                alt={AppContext.defaultAlt}
                                width={145}
                                height={30}
                                className="ltx_footer__logo"
                            />
                        </a>
                    </Link> */}
                    <div className="ltx_SocialHolder">
                        <Link href={AppContext.externalLinks.facebook} passHref>
                            <a target="_blank" rel="noreferrer">
                                <Image
                                    src="/footer_facebook_icon.svg"
                                    width={36}
                                    height={36}
                                    alt={AppContext.defaultAlt}
                                />
                            </a>
                        </Link>
                        <Link href={AppContext.externalLinks.twitter} passHref>
                            <a target="_blank" rel="noreferrer">
                                <Image
                                    src="/footer_twitter_icon.svg"
                                    width={36}
                                    height={36}
                                    alt={AppContext.defaultAlt}
                                />
                            </a>
                        </Link>
                        <Link
                            href={AppContext.externalLinks.instagram}
                            passHref
                        >
                            <a target="_blank" rel="noreferrer">
                                <Image
                                    src="/footer_instagram_icon.svg"
                                    width={36}
                                    height={36}
                                    alt={AppContext.defaultAlt}
                                />
                            </a>
                        </Link>
                        <Link href={AppContext.externalLinks.linkedin} passHref>
                            <a target="_blank" rel="noreferrer">
                                <Image
                                    src="/footer_linkedin_icon.svg"
                                    width={36}
                                    height={36}
                                    alt={AppContext.defaultAlt}
                                />
                            </a>
                        </Link>
                        <Link href={AppContext.externalLinks.tiktok} passHref>
                            <a target="_blank" rel="noreferrer">
                                <Image
                                    src="/footer_tiktok_icon.svg"
                                    width={36}
                                    height={36}
                                    alt={AppContext.defaultAlt}
                                />
                            </a>
                        </Link>
                    </div>
                </div>
                <div>
                    <ul>
                        <li>
                            <Link href={"#"}>
                                <a>Company</a>
                            </Link>
                        </li>
                        <li>
                            <Link href={"/aboutus"}>
                                <a>About Us</a>
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link href={"/WeCreators_TermsofUse.pdf"} passHref>
                                <a
                                    className="txt_Footnote2 mx-10"
                                    // href="https://photoshootgoals.com/terms-and-conditions/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of Use
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={"/WeCreators_PrivacyPolicy.pdf"}
                                passHref
                            >
                                <a
                                    className="txt_Footnote2 mx-10"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link href={"/sitemap"}>
                                <a className="txt_Footnote2 mx-10">Sitemap</a>
                            </Link>
                        </li>
                        {/* {AppContext.sections &&
                            AppContext.sections
                                .filter(
                                    (section: any) => section.section == null
                                )
                                .map((section: any, sectionIndex: number) => (
                                    <li key={sectionIndex}>
                                        <Link
                                            href={
                                                (section.library_section &&
                                                    section.library_section !==
                                                        true) ||
                                                !section.library_section
                                                    ? `/sections/${section.slug}`
                                                    : `/library/`
                                            }
                                        >
                                            <a className="ltx_footer__section">
                                                {section.name}
                                            </a>
                                        </Link>
                                    </li>
                                ))} */}
                    </ul>
                </div>
            </div>
        </footer>
    );
}
