declare const window: any;

// log the pageview with their URL
export const pageview = (url: any) => {
    if (typeof process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS !== "undefined") {
        if (typeof window !== "undefined") {
            window.gtag &&
                window.gtag(
                    "config",
                    process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
                    {
                        page_path: url,
                    }
                );
        }
    }
};

// log specific events happening.
export const event = ({ action, params }: any) => {
    if (typeof process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS !== "undefined") {
        if (typeof window !== "undefined") {
            window.gtag && window.gtag("event", action, params);
        }
    }
};
