import { useEffect } from "react";

export default function LeanplumComponent() {
    useEffect(() => {
        // Leanplum ----------------------------------------
        if (process.browser && typeof window.Leanplum === "undefined") {
            (async () => {
                const Leanplum = (await import("leanplum-sdk")).default;
                switch (process.env.NODE_ENV) {
                    default:
                    case "development":
                        Leanplum.setAppIdForDevelopmentMode(
                            process.env.NEXT_PUBLIC_LEANPLUM_APP_ID || "",
                            process.env.NEXT_PUBLIC_LEANPLUM_DEVELOPMENT_KEY ||
                                ""
                        );
                        break;
                    case "production":
                        Leanplum.setAppIdForProductionMode(
                            process.env.NEXT_PUBLIC_LEANPLUM_APP_ID || "",
                            process.env.NEXT_PUBLIC_LEANPLUM_PRODUCTION_KEY ||
                                ""
                        );
                        break;
                }
                // console.log("in Leanplum", Leanplum && Leanplum);
                // Leanplum.setVariables({
                //     items: {
                //         color: "red",
                //         size: 20,
                //         showBadges: true,
                //     },
                //     showAds: true,
                // });
                // Leanplum.start(function (success: any) {
                //     console.log("Success: ", success);
                //     console.log("Variables: ", Leanplum.getVariables());
                // });
                window.Leanplum = Leanplum;
            })();
        }
        // End of Leanplum -----------------------------------
    }, []);

    return <></>;
}
