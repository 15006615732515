import Navigation from "./navigation";
import Subscribe from "./subscribe";
import Footer from "./footer";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useAppContext } from "./../context/state";
import * as ga from "../lib/ga";
import nextSeoConfig from "../next-seo.config";
import { NextSeo } from "next-seo";
import LeanplumComponent from "@/components/leanplum";

export interface Props {
    children?: React.ReactNode;
    seo?: any;
}

export default function Layout({ children, seo }: Props) {
    const [y, setY] = useState(0);
    const [loading, setLoading] = useState(false);

    const router = useRouter();

    const AppContext = useAppContext();

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            setY(window.scrollY);
        },
        [y]
    );
    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    // Loading
    useEffect(() => {
        const handleRouteComplete = (url: any) => {
            //console.log("handleRouteComplete url=" + url);
            setLoading(false);
            AppContext.setLoading(false);
            ga.pageview(url);
        };
        const handleRouteStart = (url: any) => {
            //console.log("handleRouteStart url=" + url);
            // console.log("handleRouteStart AppContext", AppContext);
            AppContext.setLoading(true);
            setLoading(true);
        };

        //When the component is mounted, subscribe to router changes
        //and log those page views
        router.events.on("routeChangeComplete", handleRouteComplete);
        router.events.on("routeChangeStart", handleRouteStart);
        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off("routeChangeComplete", handleRouteComplete);
            router.events.off("routeChangeStart", handleRouteStart);
        };
    }, [router.events]);

    const mainClassName = y > 20 || router.asPath !== "/" ? "ltx_Short" : "";
    const mainClassExtra = loading ? "ltx_Loading" : "";

    //console.info("seo", seo);
    const combinedSeo = { ...nextSeoConfig, ...seo };
    //console.info("combinedSeo", combinedSeo);
    return (
        <div className={mainClassExtra}>
            <LeanplumComponent />
            <NextSeo {...combinedSeo} />
            <Navigation />
            <main className={mainClassName}>{children}</main>
            <Subscribe />
            <Footer />
        </div>
    );
}
