export default {
    title: "SeeCreators",
    description:
        "See the creators. Become part of the community. Learn from those with experience and help those without. SeeCreators.com",
    canonical: "https://seecreators.com/",
    noindex: process.env.NEXT_PUBLIC_NOINDEX || null,
    nofollow: process.env.NEXT_PUBLIC_NOFOLLOW || null,
    openGraph: {
        url: "https://seecreators.com/",
        title: "SeeCreators",
        description:
            "SeeCreators.com is a community for social media creators, influencers and the sharing of knowledge. For those who ask 'How?' and for those who can offer the answers.",
        images: [
            {
                url: "/default.png",
                width: 500,
                height: 227,
                alt: "The community for social media creators and influencers",
            },
        ],
        site_name: "SeeCreators",
    },
    twitter: {
        handle: "@ltx",
        site: "@ltx",
        cardType: "summary_large_image",
    },
};
