import React, { MouseEvent, useRef, useState, useEffect } from "react";
import { fetchAPI } from "lib/api";
import debounce from "lodash.debounce";
import Link from "next/link";
import { useAppContext } from "./../context/state";
import { parseArticle, trackEvent } from "lib/basic";
import { reduceRight } from "lodash";

const TAGS_MAXLENGTH = 10;

export interface Props {
    color?: any;
    tags?: any;
    selectedTags?: any;
    clickOnTag?(tag: string): void;
    searchChange?(e: React.FormEvent<HTMLInputElement>): void;
    handleHide?(e: React.MouseEvent<HTMLElement>): void;
}

const articlesList = (articles: any) => {
    return (
        <ul>
            {articles.map((article: any) => (
                <li key={article.slug}>
                    <Link href={article.parsed_url} passHref>
                        <a
                            onClick={() => {
                                trackEvent(
                                    "NAVIGATION_SEARCH_CLICK_ON_RESULT",
                                    article.parsed_url
                                );
                            }}
                        >
                            {article.title}
                            <span>{article.section?.name}</span>
                        </a>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const creatorsList = (creators: any) => {
    return creators.map((creator: any) => (
        <li key={creator.slug}>
            <Link href={"/creators/" + creator.slug} passHref>
                <a
                    onClick={() => {
                        trackEvent(
                            "NAVIGATION_SEARCH_CLICK_ON_RESULT",
                            "/creators/" + creator.slug
                        );
                    }}
                >
                    {creator.name}
                    <span>Creator</span>
                </a>
            </Link>
        </li>
    ));
};

export default function SearchBar({
    color,
    tags,
    selectedTags,
    clickOnTag,
    searchChange,
    handleHide,
}: Props): JSX.Element {
    const inputRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [userQuery, setUserQuery] = useState("");
    const [articles, setArticles] = useState<any>([]);
    const [creators, setCreators] = useState<any>([]);
    const [typed, setTyped] = useState(false);

    const AppContext = useAppContext();

    const focusOnInput = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    useEffect(() => {
        inputRef?.current?.focus();
        setTyped(false);
        setLoading(false);
        setLoaded(false);
    }, []);

    const queryService = async (q: string) => {
        setArticles([]);
        setCreators([]);
        if (q.length === 0) return;

        trackEvent("NAVIGATION_SEARCH_QUERY", q);

        setLoading(true);
        setLoaded(false);
        //console.info("queryService q", q);
        const query_articles: any = await fetchAPI(
            `/articles?title_a_contains=${q}&hidden=false`
        );
        // Parsing
        query_articles.map((article: any) => {
            article = parseArticle(article, AppContext.variant);
        });
        const query_creators: any = await fetchAPI(
            `/creators?name_contains=${q}`
        );
        setLoading(false);
        setLoaded(true);
        setArticles(query_articles);
        setCreators(query_creators);
    };

    const delayedQuery = useRef(
        debounce((q) => {
            queryService(q);
        }, 500)
    ).current;

    const handleChange = (value: string) => {
        setTyped(true);
        setUserQuery(value);
        delayedQuery(value);
        setLoaded(false);
    };

    const loadingClass = loading ? "ltx_Loading" : "";

    return (
        <div
            className="ltx-SearchBar"
            style={{
                backgroundColor: color ? color : null,
            }}
            onClick={handleHide}
        >
            <div className={"ltx_Container " + loadingClass}>
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Type your search here"
                    onChange={(e) => handleChange(e.target.value)}
                    onClick={focusOnInput}
                    onFocus={focusOnInput}
                />
                {userQuery.length > 0 && typed === true && loaded === true ? (
                    <div className="ltx_Results">
                        {/* <strong>Articles</strong> */}
                        {articles.length > 0 ? (
                            <ul>{articlesList(articles)}</ul>
                        ) : (
                            <div>
                                <h3>
                                    No articles matching your search. Try other
                                    interesting ones.
                                </h3>
                                <ul>
                                    {articlesList(AppContext.suggestedArticles)}
                                </ul>
                            </div>
                        )}

                        {/* <strong>Creators</strong> */}
                        {creators.length > 0 ? (
                            <ul>{creatorsList(creators)}</ul>
                        ) : (
                            <div>
                                <h3>
                                    No creators matching your search. Try other
                                    interesting ones.
                                </h3>
                                <ul>
                                    {creatorsList(AppContext.suggestedCreators)}
                                </ul>
                            </div>
                        )}
                    </div>
                ) : null}
                {/* ) : userQuery.length > 0 &&
                   articles.length === 0 &&
                   creators.length === 0 &&
                   typed === false ? (
                     <div className="ltx_Results">
                         <strong style={{ marginTop: 10 }}>
                             No results matching your search
                         </strong>
                     </div>
                 ) : null} */}
            </div>
        </div>
    );
}
