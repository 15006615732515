import { useMemo, useState, useEffect, useRef } from "react";
import { useAppContext } from "../context/state";
import Image from "next/image";
import debounce from "lodash.debounce";
import { trackEvent } from "../lib/basic";
import toast from "react-hot-toast";
import { useFormspark } from "@formspark/use-formspark";

const FORMSPARK_FORM_ID = "CXgpCwCQ";

declare module "react" {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        name?: string;
    }
}

const toastStyle = {
    style: {
        border: "1px solid #4cc7e1",
        padding: "16px",
        color: "#444",
        fontSize: 12,
        borderRadius: 31,
    },
    iconTheme: {
        primary: "#4cc7e1",
        secondary: "#FFFAEE",
    },
};

export default function Subscribe(): JSX.Element {
    const AppContext = useAppContext();

    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const textFieldRef = useRef<HTMLInputElement>(null);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (window.Leanplum && email.length > 0) {
            //console.log("window.Leanplum", window.Leanplum);
            //console.log("email", email);

            // To allow targeting the user in email campaigns, set the "email" attribute
            window.Leanplum.setUserAttributes({
                email: email,
                newsletter: email,
            });

            trackEvent("SUBSCRIBE_SUBMIT");
            //console.info("VARIABLES", window.Leanplum.getVariables());
            toast.success("Your email has been submitted.", toastStyle);
            setEmail("");
            setShowPlaceholder(true);
            if (textFieldRef.current && textFieldRef.current.value) {
                textFieldRef.current.value = "";
            }
        }
    };

    const handleEmailChange = (e: any) => {
        //console.log("handleEmailChange");
        setEmail(e.target.value);
        trackEvent("SUBSCRIBE_TYPING_EMAIL");
    };

    const debouncedChangeHandler = useMemo(
        () => debounce(handleEmailChange, 500),
        []
    );

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, []);
    const onSubmit = async (e: any) => {
        e.preventDefault();
        await submit({ email });
        if (textFieldRef.current) {
            textFieldRef.current.value = "";
        }
        alert("Form was successfully submitted. Thank you!");
    };

    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    });

    return (
        <div className="ltx-Subscribe mx-auto flex flex-col bg-white items-center">
            <a name="subscribe"></a>
            <div className="txt_Subscribe mb-0 mt-12">
                Subscribe today for our latest stories and updates!
            </div>

            {/* <Image
                src="/site_logo.svg"
                alt={AppContext.defaultAlt}
                width={256}
                height={136}
            /> */}
            <form onSubmit={onSubmit}>
                {/* <form
                action="https://gmail.us14.list-manage.com/subscribe/post"
                method="POST"
                target="_blank"
            > */}
                <input
                    type="hidden"
                    name="u"
                    value="317facc01e3cff7aaefda4b0e"
                />
                <input type="hidden" name="id" value="756581102e" />
                <input
                    className="my-16"
                    type="email"
                    name="b_email"
                    id="b_email"
                    onChange={debouncedChangeHandler}
                    onBlur={(e) => {
                        setShowPlaceholder(true);
                    }}
                    onFocus={(e) => {
                        setShowPlaceholder(false);
                    }}
                    placeholder={
                        showPlaceholder ? "Enter Your Email Address" : undefined
                    }
                    ref={textFieldRef}
                />
                <button type="submit" disabled={submitting}>
                    Submit
                </button>
            </form>
        </div>
    );
}
