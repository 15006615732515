import { getStrapiMediaURL } from "./api";
import { useRouter } from "next/router";
import * as ga from "../lib/ga";

// _type = image_a | image_b | square_image_a | square_image_b
// _size = thumbnail | large | medium | small |  original
export function getEntityImage(
    _entity: any,
    _type = "image",
    _size = "original",
    _optimize = false
) {
    let url: string = "";

    if (typeof _entity === "undefined") return "";

    // patch for thumbnails
    if (_size === "thumbnail") _size = "small";

    // function to get proper url
    const getProperImgElementURL = (_imgElement: any, _size = "original") => {
        // console.log(
        //     "getProperImgElementURL _imgElement",
        //     _imgElement,
        //     ", size=" + _size
        // );
        if (_imgElement) {
            if (
                typeof _imgElement.formats === "undefined" ||
                _size === "original"
            ) {
                return _imgElement.url;
            } else {
                if (
                    _imgElement.formats &&
                    typeof _imgElement.formats[_size] !== "undefined"
                ) {
                    return _imgElement.formats[_size].url;
                } else {
                    return _imgElement.url;
                }
            }
        } else {
            return "";
        }
    };

    // check for the desired image
    if (typeof _entity[_type]) {
        // we have a match
        url = getProperImgElementURL(_entity[_type], _size);
    } else {
        if (typeof _entity.image !== "undefined") {
            // we have image
            url = getProperImgElementURL(_entity["image"], _size);
        } else if (typeof _entity.square_image !== "undefined") {
            // we have square_image
            url = getProperImgElementURL(_entity["square_image"], _size);
        } else if (typeof _entity.avatar !== "undefined") {
            // we have avatar
            url = getProperImgElementURL(_entity["avatar"], _size);
        } else if (typeof _entity.profile_image !== "undefined") {
            // we have profile_image
            url = getProperImgElementURL(_entity["profile_image"], _size);
        }
    }
    // Patch with NEXT optimization - /_next/image?url=%2F_next%2Fimage%3Furl%3Dhttps%3A%2F%2Fltx.sfo3.digitaloceanspaces.com%2Fa53c19d2da8df26824613ac010ac5075.jpg%26w%3D1080%26q%3D75&w=640&q=75
    if (_optimize && _optimize === true) {
        url = `/_next/image?url=${url}&w=1080&q=75`;
    }
    return getStrapiMediaURL(url);
}

export function count_duplicate(a: string[]) {
    let counts: any = {};

    for (let i = 0; i < a.length; i++) {
        if (counts[a[i]]) {
            counts[a[i]] += 1;
        } else {
            counts[a[i]] = 1;
        }
    }
    for (let prop in counts) {
        if (counts[prop] >= 2) {
            // console.log(prop + " counted: " + counts[prop] + " times.");
        }
    }
    // console.log(counts);
    return counts;
}

export function parseArticle(article: any, variant: string) {
    // let result = Object.assign({}, article);

    //console.log("parseArticle article", article, "variant", variant);

    // try variants
    article.title =
        variant === "a" && article.title_a ? article.title_a : article.title_b;
    article.title =
        variant === "b" && article.title_b ? article.title_b : article.title_a;
    article.announce =
        variant === "a" && article.announce_a
            ? article.announce_a
            : article.announce_b;
    article.announce =
        variant === "b" && article.announce_b
            ? article.announce_b
            : article.announce_a;
    article.image =
        variant === "a" && article.image_a ? article.image_a : article.image_b;
    article.image =
        variant === "b" && article.image_b ? article.image_b : article.image_a;
    article.square_image =
        variant === "a" && article.square_image_a
            ? article.square_image_a
            : article.square_image_b;
    article.square_image =
        variant === "b" && article.square_image_b
            ? article.square_image_b
            : article.square_image_a;
    article.style =
        variant === "a" && article.style_a ? article.style_a : article.style_b;
    article.style =
        variant === "b" && article.style_b ? article.style_b : article.style_a;

    article.text =
        variant === "a" && article.text_a ? article.text_a : article.text_b;
    article.text =
        variant === "b" && article.text_b ? article.text_b : article.text_a;

    article.variant_ext =
        (article.title_a &&
            article.title_a.length > 0 &&
            article.title_b &&
            article.title_b.length > 0) ||
        (article.announce_a &&
            article.announce_a.length > 0 &&
            article.announce_b &&
            article.announce_b.length > 0) ||
        (article.image_a && article.image_b) ||
        (article.square_image_a && article.square_image_b) ||
        (article.style_a > 0 && article.style_b)
            ? "&var=" + variant
            : "";

    article.parsed_url =
        article.direct_url && article.direct_url.toString().length > 0
            ? article.direct_url
            : `/articles/` + article.slug + article.variant_ext;

    return article;
}

const TRACKING_DATA: Record<string, any> = {
    // Creator Share
    CREATOR_SHARE_FACEBOOK: {
        action: "Share - Facebook",
        category: "Creators pages",
        label: "Creators pages",
    },
    CREATOR_SHARE_TWITTER: {
        action: "Share - Twitter",
        category: "Creators pages",
        label: "Creators pages",
    },
    CREATOR_SHARE_LINKEDIN: {
        action: "Share - Linkedin",
        category: "Creators pages",
        label: "Creators pages",
    },
    CREATOR_SHARE_COPY: {
        action: "Share - CopyToClipboard",
        category: "Creators pages",
        label: "Creators pages",
    },

    // Article Share
    ARTICLE_SHARE_FACEBOOK: {
        action: "Share - Facebook",
        category: "Articles pages",
        label: "Articles pages",
    },
    ARTICLE_SHARE_TWITTER: {
        action: "Share - Twitter",
        category: "Articles pages",
        label: "Articles pages",
    },
    ARTICLE_SHARE_LINKEDIN: {
        action: "Share - Linkedin",
        category: "Articles pages",
        label: "Articles pages",
    },
    ARTICLE_SHARE_COPY: {
        action: "Share - CopyToClipboard",
        category: "Articles pages",
        label: "Articles pages",
    },
    // Creator tag (clicking on a tag)
    CREATOR_TAG: {
        action: "Click - Tag",
        category: "Creators pages",
        label: "Creators pages",
    },
    // Article tag (clicking on a tag)
    ARTICLE_TAG: {
        action: "Click - Tag",
        category: "Articles pages",
        label: "Articles pages",
    },
    // Article Related creators
    ARTICLE_RELATED_CREATORS: {
        action: "Related Creators Click",
        category: "Articles pages",
        label: "Articles pages",
    },
    // Creator's Other Creators
    CREATOR_OTHER_CREATORS: {
        action: "Other Creators Click",
        category: "Creators pages",
        label: "Creators pages",
    },
    // Article Related Content
    ARTICLE_RELATED_CONTENT: {
        action: "Related Content Click",
        category: "Articles pages",
        label: "Articles pages",
    },
    // Creator's Related Content
    CREATOR_RELATED_CONTENT: {
        action: "Related Content Click",
        category: "Creators pages",
        label: "Creators pages",
    },
    // Section's Hightlighted Content
    SECTION_HIGHLIGHTS_CONTENT: {
        action: "Highlights Content Click",
        category: "Section pages",
        label: "Section pages",
    },
    // Section's Hightlighted Creators
    SECTION_HIGHLIGHTS_CREATORS: {
        action: "Highlights Creator Click",
        category: "Section pages",
        label: "Section pages",
    },
    // Section's Featured Creators
    SECTION_FEATURED_CREATORS: {
        action: "Featured Creator Click",
        category: "Section pages",
        label: "Section pages",
    },
    // Navigation
    NAVIGATION_LOGO: {
        action: "Logo Click",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_LEFT_ARTICLE: {
        action: "Left Article Click",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_RIGHT_ARTICLE: {
        action: "Right Article Click",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_HOME: {
        action: "Home Click",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_SECTION: {
        action: "Section Click",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_MOBILE_EXPAND: {
        action: "Navigation Expand",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_MOBILE_COLLAPSE: {
        action: "Navigation Collapse",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_SHOW_SEARCH: {
        action: "Navigation Show Search",
        category: "Top Nav",
        label: "Navigation",
    },
    NAVIGATION_SEARCH_QUERY: {
        action: "Navigation Search Query",
        category: "Search",
        label: "Search",
    },
    NAVIGATION_SEARCH_CLICK_ON_RESULT: {
        action: "Navigation Search Click On Result",
        category: "Search",
        label: "Search",
    },
    HOMEPAGE_TOP_ARTICLE: {
        action: "Clicked Top box",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_LEFT_ARTICLE: {
        action: "Clicked Left Article",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_RIGHT_ARTICLE: {
        action: "Clicked Right Article",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_BIG_ARTICLE: {
        action: "Clicked Big Article Promotion",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_DISCOVER: {
        action: "Clicked Item - Discover",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_SECOND_COVER_ARTICLE: {
        action: "Clicked Second Cover Article",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_LIBRARY_ITEM: {
        action: "Clicked Item - Library",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_COMMUNITY_ITEM: {
        action: "Clicked Item - Community",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_LIBRARY_ALL: {
        action: "Clicked All - Library",
        category: "Homepage",
        label: "Homepage",
    },
    HOMEPAGE_COMMUNITY_ALL: {
        action: "Clicked All - Community",
        category: "Homepage",
        label: "Homepage",
    },
    SUBSCRIBE_TYPING_EMAIL: {
        action: "Typed in email field",
        category: "Newsletter_signups",
        label: "Newsletter_signups",
    },
    SUBSCRIBE_SUBMIT: {
        action: "Submit in Subscribe",
        category: "Newsletter_signups",
        label: "Newsletter_signups",
    },
};

export function trackEvent(key: string, value: string | number = "") {
    //console.log(`trackEvent KEY=${key} | value=${value}`);

    // console.log(
    //     `trackEvent KEY=${key} | action=${TRACKING_DATA[key].action},category=${TRACKING_DATA[key].category},label=${TRACKING_DATA[key].label},value=${value}`
    // );

    const path = window.location.pathname.split("/");
    const slug = path.length > 0 ? path.pop() : "";

    const pathArray = window.location.pathname.split("/");
    // console.log(`trackEvent pathArray`, pathArray);
    // console.log(`trackEvent SLUG=` + slug);

    // Tracking event to Analytics
    ga.event({
        action: TRACKING_DATA[key].action,
        params: {
            event_category: TRACKING_DATA[key].category,
            event_label: TRACKING_DATA[key].label,
            value: value && value.toString().length > 0 ? value : slug,
        },
    });
}
